import React from 'react';
import config from "../../config/config.json";


export type ChatBailleurFormProps = {
  onSubmit: (bailleur: ChatBailleur) => void;
}

// Extract slugs from the configuration
const slugs = config.clients.filter(client => client.bailleur).map(client => client.slug);

export type ChatBailleur = typeof slugs[number];

export default function ChatBailleurForm(props: ChatBailleurFormProps) {

  const [bailleur, setBailleur] = React.useState<ChatBailleur | undefined>(undefined);
  const [disabled, setDisabled] = React.useState<boolean>(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!bailleur) return;
    props.onSubmit(bailleur as ChatBailleur);
    setDisabled(true);
    // Handle form submission
  };

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div className={"chat-message-holder sent"}>
      <div className={"chat-message-box"}>
        <div className={`chat-message`}>
          <div className={"chat-message-content"}>
            <div className="form-wrapper">
              <form onSubmit={handleSubmit} className="space-y-2 w-full" id="chat-appointment">
                <p>Mon bailleur est:</p>
                <label htmlFor={"bailleur"} className="font-medium text-gray-700">
                  Bailleur
                </label>
                <select
                  id="bailleur"
                  value={bailleur}
                  onChange={(e) => setBailleur(e.target.value as ChatBailleur)}
                >
                  <option value="">Sélectionner...</option>
                  {slugs.map(slug => (
                    <option key={slug} value={slug}>{capitalizeFirstLetter(slug)}</option>
                  ))}
                </select>
                <button type="submit" className={"submit"} disabled={disabled}>
                  Valider
                </button>
              </form>
              {disabled && <div className="appointment-form-mask"></div>}
            </div>
          </div>
        </div>
      </div>
      <h3 className={"chat-message-sender"}>Moi</h3>
    </div>
  );
}
