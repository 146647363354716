import React, {useState} from "react";
import './ChatUserAppointment.css';

export type ChatUserAppointmentProps = {
  onSubmit: (data: ChatUserAppointmentData) => void;
}

export type ChatUserAppointmentAvailability = "morning" | "afternoon" | "both";

export type ChatUserAppointmentData = {
  monday: ChatUserAppointmentAvailability | undefined;
  tuesday: ChatUserAppointmentAvailability | undefined;
  wednesday: ChatUserAppointmentAvailability | undefined;
  thursday: ChatUserAppointmentAvailability | undefined;
  friday: ChatUserAppointmentAvailability | undefined;
}

export default function ChatUserAppointment(props: ChatUserAppointmentProps) {
  const [data, setData] = useState<ChatUserAppointmentData>({
    monday: undefined,
    tuesday: undefined,
    wednesday: undefined,
    thursday: undefined,
    friday: undefined
  });
  const [disabled, setDisabled] = useState<boolean>(false);

  const handleChange = (day: keyof ChatUserAppointmentData, value: ChatUserAppointmentAvailability) => {
    setData((prevData) => ({
      ...prevData,
      [day]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setDisabled(true);
    props.onSubmit(data);
  };

  const daysInFrench = {
    monday: "Lundi",
    tuesday: "Mardi",
    wednesday: "Mercredi",
    thursday: "Jeudi",
    friday: "Vendredi"
  };

  return (
    <div className={"chat-message-holder sent"}>
      <div className={"chat-message-box"}>
        <div className={`chat-message`}>
          <div className={"chat-message-content"}>
            <div className="form-wrapper">
              <form onSubmit={handleSubmit} className="space-y-2 w-full" id="chat-appointment">
                <p>Je suis disponible:</p>
                {Object.keys(daysInFrench).map((day) => (
                  <div key={day} className="flex flex-col">
                    <label htmlFor={day} className="font-medium text-gray-700">
                      {daysInFrench[day as keyof typeof daysInFrench]}:
                    </label>
                    <select
                      id={day}
                      value={data[day as keyof ChatUserAppointmentData] || ""}
                      onChange={(e) => handleChange(day as keyof ChatUserAppointmentData, e.target.value as ChatUserAppointmentAvailability)}
                      disabled={disabled}
                    >
                      <option value="">Aucune</option>
                      <option value="morning">Le matin</option>
                      <option value="afternoon">L'après-midi</option>
                      <option value="both">Les deux</option>
                    </select>
                  </div>
                ))}
                <button type="submit" className={"submit"} disabled={disabled}>
                  Valider
                </button>
              </form>
              {disabled && <div className="appointment-form-mask"></div>}
            </div>
          </div>
        </div>
      </div>
      <h3 className={"chat-message-sender"}>Moi</h3>
    </div>
  );
}